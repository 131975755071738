<template>
	<div class="reginbox" v-show="showflag">
		<div class="resgin_title">
			用户注册
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div class="inputline">
				<el-form-item label="上传头像">
					<el-upload class="avatar-uploader" :action="$URL+'/api/login/upload'"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="imageUrl" :src="imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</div>
			
			<div class="inputline">
				<el-form-item label="我的姓名" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="我的性别" required>
					<el-radio-group v-model="ruleForm.sex">
					    <el-radio :label="0">女</el-radio>
					    <el-radio :label="1">男</el-radio>
					</el-radio-group>
				</el-form-item>
			</div>
			<div class="inputline">
				<el-form-item label="出生日期" prop="date">
					<el-date-picker
					      v-model="ruleForm.date"
					      type="date"
					      placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="所在地区" prop="region">
					<el-select v-model="ruleForm.region" placeholder="请选择区域">
						<el-option label="银川市" value="shanghai"></el-option>
						<el-option label="石嘴山市" value="beijing"></el-option>
						<el-option label="吴忠市" value="beijing"></el-option>
						<el-option label="固原市" value="beijing"></el-option>
						<el-option label="中卫市" value="beijing"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="inputline">
				<el-form-item label="所属党派" prop="dp">
					<el-select v-model="ruleForm.dp" placeholder="请选择所属党派">
						<el-option label="中共" value="shanghai"></el-option>
						<el-option label="民革" value="beijing2"></el-option>
						<el-option label="台盟" value="beijing3"></el-option>
						<el-option label="无党派" value="beijing4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属界别" prop="jb">
					<el-select v-model="ruleForm.jb" placeholder="请选择所属界别">
						<el-option label="中共" value="shanghai1"></el-option>
						<el-option label="民革" value="beijing2"></el-option>
						<el-option label="民盟" value="beijing3"></el-option>
						<el-option label="民建" value="beijing4"></el-option>
						<el-option label="无" value="beijing5"></el-option>
									
					</el-select>
				</el-form-item>
			</div>
			<div class="inputline">
				<el-form-item label="工作单位" prop="dw">
					<el-input v-model="ruleForm.dw" placeholder="请输入工作单位"></el-input>
				</el-form-item>
				<el-form-item label="联系地址"  prop="dz">
					<el-input v-model="ruleForm.dz" placeholder="请输入联系地址"></el-input>
				</el-form-item>
			</div>
			<div class="inputline">
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="工作职务"  prop="zw">
					<el-input v-model="ruleForm.zw" placeholder="请输入工作职务"></el-input>
				</el-form-item>
			</div>
			<div class="inputline">
				<el-form-item label="登录密码" prop="password">
					<el-input v-model="ruleForm.password" type="password" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码"  prop="password2">
					<el-input v-model="ruleForm.password2" type="password" placeholder="请再次输入密码"></el-input>
				</el-form-item>
			</div>
			
			<div class="inputline" style="justify-content: center;margin-top: 2vh;">				
				<el-button type="" style="margin-right: 0.5rem;" @click="locse">关闭</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showflag:false,
				imageUrl: '',
				ruleForm: {
					name: '',
					sex:1,
					region: '',
					dp: '',
					jb: '',
					date: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: '',
					dw: '',
					dz: '',
					phone: '',
					zw: '',
					password: '',
					password2: '',
				},
				rules: {
					name: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					
					dw: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					dz: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					zw: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					password2: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						}
					],
					region: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					dp: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					jb: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					date: [{
						type: 'date',
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '请至少选择一个活动性质',
						trigger: 'change'
					}],
					resource: [{
						required: true,
						message: '请选择活动资源',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请填写活动形式',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			locse(){
				this.showflag = false
				this.ruleForm = {
					name: '',
					sex:1,
					region: '',
					dp: '',
					jb: '',
					date: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: '',
					dw: '',
					dz: '',
					phone: '',
					zw: '',
					password: '',
					password2: '',
				}
			},
			submitForm(formName) {
			        this.$refs[formName].validate((valid) => {
			          if (valid) {
			            this.$message.success('创建成功')
						setTimeout(()=>{
							this.locse()
						},1000)
			          } else {
			            console.log('error submit!!');
			            return false;
			          }
			        });
			      },
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg'||file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import 'index.scss';
</style>