<template>
	<div class="box_login">
		<div style="display: flex;align-items: center;justify-content: center;width: 100%;">
			<img src="../../assets/logo.png" alt="" style="width: 1.1rem;height: 1.1rem;" />
			<img src="../../assets/title.png" alt="" style="width: 11.8rem;height: auto;margin-left: 0.35rem;" />
		</div>
		<div style="margin-top: 80px;display: flex;align-items: center;">
			<div
				style="width: 8.7rem;display: flex;flex-wrap: wrap;align-items: center;justify-content: space-between;">
				<div class="hover_item">
					<img src="../../assets/login1.png" alt=""
						style="width: 2.88rem;height: 2.38rem;margin-bottom:3px;" />
					<div class="text_item">
						主要用于政协委员的在线提交、查询、跟踪提案，政府的督查、管理、催办、查询、统计、相关部门承办、反馈、接受委员考评和监督
					</div>
				</div>
				<div class="hover_item">
					<img src="../../assets/login2.png" alt=""
						style="width: 2.88rem;height: 2.38rem;margin-bottom:3px;" />
					<div class="text_item">
						主要用于委员社情民意提交、审核工作，实现了社情民意的在线提交、审核、采用与反馈情况登记、发布、统计汇总、图表分析等功能
					</div>
				</div>
				<div class="hover_item">
					<img src="../../assets/login3.png" alt=""
						style="width: 2.88rem;height: 2.38rem;margin-bottom:3px;" />
					<div class="text_item">
						云端委员驿站将线下的各个委员驿站打造成线上互通的驿站联盟，更好地方便委员联系群众和反映民意，委员及群众可以通过线上了解附近委员驿站工作情况，开展多维度、立体化沟通
					</div>
				</div>
				<div class="hover_item">
					<img src="../../assets/login4.png" alt="" style="width: 2.88rem;height: 2.38rem;" />
					<div class="text_item">
						公文流转系统可以通过网络，完成公文的收发签批、呈报。支持多端使用，移动端方便领导随时随地办公，快捷及时的处理各机关工作事项
					</div>
				</div>
				<div class="hover_item">
					<img src="../../assets/login5.png" alt="" style="width: 2.88rem;height: 2.38rem;" />
					<div class="text_item">
						主要包含委员基本信息管理、委员信息报送体系及委员履职评价三个部分。政切机关通过本系统对委员的基本信息和委员档案进行管理
					</div>
				</div>
				<div class="hover_item">
					<img src="../../assets/login6.png" alt="" style="width: 2.88rem;height: 2.38rem;" />
					<div class="text_item">
						大数据可视化是通过合理的布局设计，将各项委员履职和政协工作数据更直观、更形象地展现出来，通过数据公开，直观反映出履职成绩、共享有效信息，快速清晰地发现问题，激活组织间的交流与协作
					</div>
				</div>
			</div>
			<div
				style="width: 2.89rem;height: 4.8rem;background: rgba(255,255,255,0.7);border: 3px solid #FFFFFF;margin-left: 3px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
				<div style="font-size: 0.32rem;color: #024ABE;">用户登录</div>
				<el-input class='input_box' v-model="username" :placeholder="isyzmlogin?'请输入手机号':'请输入用户账号'"
					style="width: 80%;margin-top: 0.5rem;">
					<i slot="prefix" :class="isyzmlogin?'el-icon-mobile-phone':'el-input__icon el-icon-user-solid'"
						style="font-size: 0.24rem;"></i>
				</el-input>
				
				<el-input v-if="!isyzmlogin" class='input_box' v-model="password" type="password" placeholder="请输入账号密码"
					style="width: 80%;margin-top: 0.2rem;">
					<i slot="prefix" class="el-input__icon el-icon-lock" style="font-size: 0.24rem;"></i>
				</el-input>
				
				
				<el-input v-if="isyzmlogin" class='input_box' v-model="code" type="text" placeholder="输入验证码"
					style="width: 80%;margin-top: 0.2rem;">
					<template slot="append">
						<div style="cursor: pointer;font-size: 0.14rem;" @click="sendCode">
							{{timer>0?timer+'秒后重新发送':'获取验证码'}}
						</div>
					</template>
				</el-input>
				<div @click="isyzmlogin=!isyzmlogin"
					style="display: flex;justify-content: flex-end;margin-top: 0.17rem;color: #409EFF;font-size: 0.16rem;cursor: pointer;">
					{{isyzmlogin?'账号密码登录':'验证码登录'}}
				</div>
				<div @click="login"
					style="cursor: pointer;width: 80%;padding: 10px 0;background-color: #1552B4;color: #fff;font-size: 0.18rem;text-align: center;border-radius: 5px 5px 5px 5px;margin-top: 0.5rem;">
					登录
				</div>
				
				<div @click="showflag"
					style="display: flex;justify-content: flex-end;margin-top: 0.17rem;color: rgb(21,82,180);font-size: 0.16rem;cursor: pointer;font-weight: bold;">
					用户注册
				</div>
			</div>
		</div>
		
		<regin ref="regin"></regin>
	</div>
</template>

<script>
	import regin from './components/register.vue';
	export default {
		components:{ regin },
		data() {
			return {
				showregin:false,
				remenber: true,
				isyzmlogin: false, //是否为验证码登录
				timer: 0, // 倒计时计时器
				intervalId: null, // 存储setInterval的ID
				username: '',
				password: '',
				code:'',
			}
		},
		mounted() {
			document.title = '宁夏智慧政协管理后台';
		},
		created() {
			let luser = JSON.parse(localStorage.getItem('userinfo'))
			if (luser && luser.token && luser.menu.length >= 1) {
				let pathurl = this.findLastUrlNode(luser.menu)
				this.$store.commit('setUserInfo', luser)
				this.$router.push(pathurl.url)
			}
		},
		methods: {
			showflag(){
				this.$refs.regin.showflag = true
			},
			login() {
				let info = {
					id: "",
					username: "",
					token: "",
					menu: '',
					rytype:1
				}
				
				if(this.isyzmlogin){//短信登录
					if (!this.code) {
						this.$message.warning('请输入验证码！');
						return false
					}
					this.$post({
						url: '/api/login/mobile_login',
						params: {
							username: this.username,
							code: this.code
						}
					}).then((res) => {
						info.menu = res.menu
						info.rytype = res.rytype
						let pathurl = this.findLastUrlNode(res.menu)
						info.token = res.token
						info.username = this.username
						localStorage.setItem('userinfo', JSON.stringify(info)); //本地缓存
						this.$store.commit('setUserInfo', info)					
						this.$router.push(pathurl.url)
					})
					
					
				}else{//账号密码登录
					if (!this.username || !this.password) {
						this.$message.warning('请输入账号或密码！');
						return false
					}
					this.$post({
						url: '/api/login/login',
						params: {
							username: this.username,
							password: this.password,
							type: 2
						}
					}).then((res) => {
						info.menu = res.menu
						info.rytype = res.rytype
						let pathurl = this.findLastUrlNode(res.menu)
						info.token = res.token
						info.username = this.username
						localStorage.setItem('userinfo', JSON.stringify(info)); //本地缓存
						this.$store.commit('setUserInfo', info)
						// if (this.remenber) {
						// 	localStorage.setItem('userinfo', JSON.stringify(res.userinfo)); //本地缓存
						// }
						// this.$store.commit('setUserInfo', res.userinfo)
					
						this.$router.push(pathurl.url)
					})
				}
			},
			sendCode() {
				if (!this.username || this.username.length!=11) {
					this.$message.warning('请输入正确的手机号！');
					return
				}
				if (this.timer > 0) {
					this.$message.warning('请在倒计时结束后再发送')
					return; // 如果当前计时器已经在倒计时，则不进行操作
				}
				this.$post({
					url: '/api/sms/send',
					params: {
						mobile: this.username,
						event: 'login'
					}
				}).then((res) => {
					this.$message.success('验证码已发送！');
					this.startCountdown()
				})
				
			},
			startCountdown() {
				this.timer = 60; // 设置倒计时时长
				this.intervalId = setInterval(() => {
					if (this.timer > 0) {
						this.timer--;
					} else {
						clearInterval(this.intervalId); // 倒计时结束，清除计时器
						this.intervalId = null;
					}
				}, 1000);
			},
			findLastUrlNode(data) {
				let nearestNode = null;
				for (let i = 0; i < data.length; i++) {
					const node = data[i];
					if (node.url) {
						nearestNode = node;
						break; // 找到第一个具有值的URL即可停止搜索
					}
					if (node.children) {
						const childNode = this.findLastUrlNode(node.children);
						if (childNode) {
							nearestNode = childNode;
							break; // 如果子节点找到了具有值的URL，也停止搜索
						}
					}
				}

				return nearestNode;
			}
		},
		beforeDestroy() {
			// 组件销毁之前清除计时器
			if (this.intervalId !== null) {
				clearInterval(this.intervalId);
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import 'login.scss';
</style>